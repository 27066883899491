<template>
  <div>
    <ValidationObserver ref="observer">
      <v-row justify="center">
        <v-col md="11">
          <h4 class="text-h4 checkout__step-header">
            {{ $t('fields.checkout.headline_step_3') }}
          </h4>

          <p class="checkout__step-description">
            {{ $t('fields.checkout.sub_headline_step_3') }}
          </p>

          <ValidationProvider ref="checkout.legal_entity" v-slot="{ errors }" name="user.company" rules="required">
            <v-tabs v-model="activeTab" :error-messages="errors[0]" class="mb-6" color="navy base">
              <v-tabs-slider color="primary base" />

              <v-tab key="0" :ripple="false" name="b2b" @click="stripeCheckoutService.legalEntity = legalEntityB2B">
                {{ $t('fields.user.company') }}
              </v-tab>
              <v-tab key="1" :ripple="false" name="b2c" @click="stripeCheckoutService.legalEntity = legalEntityB2C">
                {{ $t('fields.user.private') }}
              </v-tab>
            </v-tabs>
          </ValidationProvider>

          <v-row v-if="stripeCheckoutService.legalEntity === legalEntityB2B">
            <v-col cols="12">
              <ValidationProvider
                ref="checkout.customer.name"
                v-slot="{ errors }"
                name="user.company_name"
                rules="required|max:191"
              >
                <v-text-field
                  v-model="stripeCheckoutService.customer.name"
                  outlined
                  dense
                  data-test="customer_name"
                  class="text-field"
                  :label="$t('fields.user.company_name')"
                  :counter="191"
                  maxlength="191"
                  :error-messages="$t(errors[0])"
                />
              </ValidationProvider>
            </v-col>

            <v-col v-if="isVatIdNeeded" cols="12">
              <ValidationProvider vid="iso_code" rules="">
                <v-text-field
                  v-show="false"
                  v-model="stripeCheckoutService.location.country_iso2"
                  outlined
                  hide-details
                  dense
                  data-test="country_iso2"
                  class="text-field"
                  :label="$t('fields.checkout.form.vat_id')"
                />
              </ValidationProvider>

              <ValidationProvider
                ref="checkout.customer.tax_id_data.vat_id"
                v-slot="{ errors }"
                name="checkout.form.vat_id"
                rules="required|vat_number:@iso_code"
                mode="lazy"
              >
                <v-text-field
                  v-model="stripeCheckoutService.customer.tax_id_data.value"
                  outlined
                  dense
                  class="text-field input-validation-vat-id"
                  :label="$t('fields.checkout.form.vat_id')"
                  persistent-hint
                  data-test="tax_id"
                  :error-messages="$t(errors[0])"
                  @input="vatIdFilter"
                >
                  <template #append-outer>
                    <HelpTooltip>
                      <template #text>
                        {{ $t('fields.checkout.form.vat_id_hint') }}
                      </template>
                    </HelpTooltip>
                  </template>
                </v-text-field>
              </ValidationProvider>
              <v-col class="text-right pa-0 pr-10">
                <v-btn text :ripple="false" color="primary" small class="ml-4" @click.stop="openVaTModal = true">
                  <span class="navy--text">{{ $t('fields.checkout.form.no_vat_id_modal.button_text') }}</span>
                </v-btn>

                <v-dialog v-model="openVaTModal" max-width="460" overlay-color="neutral lighten-1">
                  <v-card>
                    <v-card-title>
                      <h4 class="text-h4">
                        {{ $t('fields.checkout.form.no_vat_id_modal.header') }}
                      </h4>
                    </v-card-title>

                    <v-card-text class="pb-0">
                      <p
                        class="lapis--text text--lighten-1"
                        v-html="$t('fields.checkout.form.no_vat_id_modal.description')"
                      />
                    </v-card-text>

                    <v-card-actions class="pa-6">
                      <v-spacer />

                      <v-btn
                        outlined
                        rounded
                        :ripple="false"
                        color="primary"
                        large
                        class="ml-4"
                        @click="openVaTModal = false"
                      >
                        {{ $t('fields.checkout.form.cancel') }}
                      </v-btn>

                      <v-btn
                        depressed
                        rounded
                        :ripple="false"
                        color="primary"
                        large
                        class="ml-4"
                        @click="selectPrivateTab"
                      >
                        {{ $t('fields.checkout.form.no_vat_id_modal.close') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <ValidationProvider
                ref="checkout.customer.metadata.first_name"
                v-slot="{ errors }"
                name="user.firstname"
                rules="required|max:191"
              >
                <v-text-field
                  v-model="stripeCheckoutService.customer.metadata.first_name"
                  outlined
                  dense
                  class="text-field"
                  :label="$t('fields.user.firstname')"
                  data-test="firstname"
                  :error-messages="$t(errors[0])"
                />
              </ValidationProvider>
            </v-col>

            <v-col cols="6">
              <ValidationProvider
                ref="checkout.customer.metadata.last_name"
                v-slot="{ errors }"
                name="user.lastname"
                rules="required|max:191"
              >
                <v-text-field
                  v-model="stripeCheckoutService.customer.metadata.last_name"
                  outlined
                  dense
                  class="text-field"
                  data-test="lastname"
                  :label="$t('fields.user.lastname')"
                  :error-messages="$t(errors[0])"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <!-- Address Section-->
          <v-row>
            <v-col cols="12" class="pb-0">
              <GooglePlacesCombobox
                @selectedAddressFromDropdown="onAddressSelected($event)"
                @manualEntry="onManualEntry($event)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" :class="!showStateDropdown ? 'pb-0' : null">
              <ValidationProvider
                ref="checkout.customer.address.postal_code"
                v-slot="{ errors }"
                name="user.zip"
                rules="required|max:15"
              >
                <v-text-field
                  v-model="stripeCheckoutService.customer.address.postal_code"
                  outlined
                  dense
                  class="text-field"
                  data-test="zip"
                  :label="$t('fields.user.zip')"
                  :error-messages="$t(errors[0])"
                />
              </ValidationProvider>
            </v-col>

            <v-col cols="8" :class="!showStateDropdown ? 'pb-0' : null">
              <ValidationProvider
                ref="checkout.customer.address.city"
                v-slot="{ errors }"
                name="user.city"
                rules="required|max:191"
              >
                <v-text-field
                  v-model="stripeCheckoutService.customer.address.city"
                  outlined
                  dense
                  class="text-field"
                  :label="$t('fields.user.city')"
                  data-test="city"
                  :error-messages="$t(errors[0])"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row v-if="showStateDropdown">
            <v-col cols="12" class="pb-0">
              <StateDropdown
                :user-state="stripeCheckoutService.customer.address.state"
                :state-list="statesList"
                :is-preselected="false"
                @stateSelected="stripeCheckoutService.customer.address.state = $event"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" :class="showStateDropdown ? 'pt-12' : 'pt-5'">
              <CountryDropdown
                :user-country="getUserBillingCountry()"
                :country-list="countriesList"
                @countrySelected="onCountrySelected"
              />
            </v-col>
          </v-row>
          <!-- END: Address Section-->
        </v-col>
      </v-row>
    </ValidationObserver>
  </div>
</template>

<script lang="ts">
import CaStates from '@/Models/Static/ca_states.json';
import countries from '@/Models/Static/countries.json';
import UsStates from '@/Models/Static/us_states.json';
import { State } from '@/Models/Static/State';
import OptimizelyService from '@/Services/OptimizelyService';
import StripeCheckoutService from '@/Services/Subscription/StripeCheckoutService';
import { StripePaymentMethodService } from '@/Services/Subscription/StripePaymentMethodService';
import { Team } from '@/classes/auth';
import HelpTooltip from '@/components/HelpTooltip.vue';
import CountryDropdown from '@/components/Input/CountryDropdown.vue';
import GooglePlacesCombobox from '@/components/Input/GooglePlacesCombobox.vue';
import StateDropdown from '@/components/Input/StateDropdown.vue';
import store from '@/store';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { GooglePlacesSuggestions } from '../Input/GooglePlacesAutocomplete.vue';
import Country from '@/Models/Static/Country';

@Component({
  components: {
    HelpTooltip,
    StateDropdown,
    CountryDropdown,
    GooglePlacesCombobox,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    ...mapGetters('location', ['isGeoDetectionAvailable']),
  },
})
export default class AddressWithCombobox extends Vue {
  @Prop({ type: Object as PropType<StripeCheckoutService> })
  stripeCheckoutService!: StripeCheckoutService;

  @Prop({ type: Object as PropType<StripePaymentMethodService> })
  stripePaymentMethodService!: StripePaymentMethodService;

  @Prop({ type: Object as PropType<Team> })
  team!: Team;

  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  // Just legal entities ready for selection
  legalEntityB2B: string = 'b2b';
  legalEntityB2C: string = 'b2c';

  statesList: Array<State> = [];
  countriesList = countries;
  googlePlacesSuggestions: GooglePlacesSuggestions[] = [];
  googlePlacesAddress: any = '';
  isVatIdNeeded: boolean = false;

  activeTab?: any = 0;
  openVaTModal = false;
  showStateDropdown = false;
  openCountryModal = false;
  isGeoDetectionLoading = false;

  // getter from vuex
  isGeoDetectionAvailable!: boolean;

  store = store;

  async created(): Promise<void> {
    if (this.team.legal_entity === 'b2c') {
      this.activeTab = 1;
      this.stripeCheckoutService.legalEntity = this.legalEntityB2C;
    }
  }

  mounted(): void {
    this.preloadData();
  }

  updated(): void {
    this.preloadData();
  }

  preloadData(): void {
    // Set list of states for country
    const country = this.stripeCheckoutService.customer.address.country
      ? this.stripeCheckoutService.customer.address.country
      : this.team.country_from_registration;

    this.stripeCheckoutService.customer.address.country = country;
    this.setStatesList(country);
    this.toggleVatIDField(country);
  }

  // Sets the list of states for specific countries
  setStatesList(country: string): void {
    if (!country) {
      this.showStateDropdown = false;
      return;
    }
    const states: Record<string, State[]> = {
      us: UsStates,
      ca: CaStates,
    };
    this.statesList = states[country.toLowerCase()] || [];
    this.showStateDropdown = this.statesList.length > 0;
  }

  onOpenCountryModal(): void {
    OptimizelyService.trackEvent(`payment_form_not_your_country`);
    this.openCountryModal = true;
  }

  toggleVatIDField(country: string): void {
    this.isVatIdNeeded = this.stripeCheckoutService.isVatIdNeeded(country);
    if (!this.isVatIdNeeded) {
      this.stripeCheckoutService.customer.tax_id_data.value = '';
    }
  }

  translateCountriesList(): void {
    const translations: any = this.$t('fields.countries');

    countries.forEach((item) => {
      item.country = translations[item.country_iso2.toLowerCase()];
    });

    this.countriesList = countries;
  }

  onCountrySelected(country: string): void {
    this.stripeCheckoutService.customer.address.state = '';
    this.stripeCheckoutService.customer.address.country = country;
    OptimizelyService.trackEvent('payment_form_country_change');
  }

  /**
   * Will take the user vat id input and clean up whitespaces,
   * small case letters and special chars
   */
  vatIdFilter(): void {
    let tmp = this.stripeCheckoutService.customer.tax_id_data.value;

    if (tmp !== null) {
      tmp = tmp.toUpperCase();
      tmp = tmp.replace(/\s/g, '');
      tmp = tmp.replace(/[^A-Z0-9]/g, '');

      // Small delay because two-way binding would otherwise not register the change of value
      setTimeout(() => (this.stripeCheckoutService.customer.tax_id_data.value = tmp), 5);
    }
  }

  // Selects the private-tab and closes the VAT modal
  selectPrivateTab(): void {
    this.activeTab = 1;
    this.stripeCheckoutService.legalEntity = this.legalEntityB2C;
    this.openVaTModal = false;
  }

  onAddressSelected(googlePlacesData: any): void {
    if (googlePlacesData) {
      if (googlePlacesData.street && googlePlacesData.house) {
        this.stripeCheckoutService.customer.address.line1 = `${googlePlacesData.street} ${googlePlacesData.house}`;
      } else if (googlePlacesData.street && !googlePlacesData.house) {
        this.stripeCheckoutService.customer.address.line1 = googlePlacesData.street;
      }
      this.stripeCheckoutService.customer.address.postal_code = googlePlacesData.zip;
      this.stripeCheckoutService.customer.address.city = googlePlacesData.city;
      this.stripeCheckoutService.customer.address.state = googlePlacesData.state;
      this.stripeCheckoutService.customer.address.country = googlePlacesData.country_iso;
    }
  }

  onManualEntry(address: string): void {
    this.stripeCheckoutService.customer.address.line1 = address;

    if (address === '') {
      this.stripeCheckoutService.customer.address.postal_code = '';
      this.stripeCheckoutService.customer.address.city = '';
      this.stripeCheckoutService.customer.address.state = '';
    }

    this.googlePlacesAddress = '';
    this.googlePlacesSuggestions = [];
    window.dataLayer = window.dataLayer || [];
  }

  /**
   * Detects the user's country via geolocation-detection
   */
  async detectCountry(): Promise<void> {
    if (this.isGeoDetectionAvailable) {
      this.isGeoDetectionLoading = true;
      try {
        await this.$store.dispatch('location/detectCountryByGeolocation');
        this.$store.commit('subscription/setStripePaymentMethodServicePaymentType', 'card');
        // await this.stripeCheckoutService.updateCustomerVatRate(); // Not sure why this was needed since it updates tax normally
      } catch (e) {
        alert(this.$t('fields.error.location'));
      }
      this.isGeoDetectionLoading = false;
      this.openCountryModal = false;
    }
  }

  getAddressFieldFirstError(): string {
    const observer: any = this.$refs.observer;
    const errors = observer.errors;

    const firstErrorMessage = Object.keys(errors).find((key) => errors[key] && errors[key].length > 0);

    return firstErrorMessage ? errors[firstErrorMessage][0] : this.$t('fields.checkout.form.invalid');
  }

  getUserBillingCountry(): string {
    const billingCountry = this.stripeCheckoutService.customer.address.country ?? this.team.country;
    const countryFromRegistration = this.team.country_from_registration;

    let result: Country | undefined;
    if (billingCountry) {
      result = countries.find((item: Country) => item.country_iso2 === billingCountry);
    } else {
      result = countries.find((item: Country) => item.country_iso2 === countryFromRegistration);
    }

    return result ? result.country_iso2 : '';
  }
}
</script>
